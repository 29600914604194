import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import throttle from "lodash.throttle";
import logo from "../../images/logo.png";

const Header = () => {
  const [menuOpened, setMenuOpened] = useState(false);
  const [isSticky, setIsSticky] = useState(false);
  const [headerHeight, setHeaderHeight] = useState(50);

  const toggleMenu = () => {
    setMenuOpened(!menuOpened);
  };

  const handleNavClick = (e) => {
    const elementId = e.target.hash.substring(1);
    const domElement = document.getElementById(elementId);

    toggleMenu();

    if (domElement) {
      e.preventDefault();
      const elementPosition = domElement.offsetTop;
      const offsetPosition = elementPosition - headerHeight;

      window.scrollTo({
        top: offsetPosition,
        behavior: "smooth",
      });
    }
  };

  const handleScroll = () => {
    const windowTop = window.scrollY + 1;
    setIsSticky(windowTop > 150);
  };

  const setBodyPaddingTop = () => {
    setHeaderHeight(document.querySelector("header").offsetHeight);
    document.getElementsByTagName(
      "body"
    )[0].style.paddingTop = `${headerHeight}px`;
  };

  useEffect(() => {
    setBodyPaddingTop();
  });

  useEffect(() => {
    const throttleScroll = throttle(() => handleScroll(), 200);
    const throttleResize = throttle(() => setBodyPaddingTop(), 200);
    window.addEventListener("scroll", throttleScroll);
    window.addEventListener("resize", throttleResize);
    return () => {
      window.removeEventListener("scroll", throttleScroll);
      window.removeEventListener("resize", throttleResize);
    };
  });

  return (
    <header
      className={`bg-white body-font fixed top-0 z-50 w-full shadow ${
        isSticky ? "animate__animated animate__fadeInDown" : ""
      }`}
    >
      <div className="container mx-auto px-5 py-6 flex flex-wrap flex-row items-center justify-between">
        <Link to="/">
          <img width="100" height="52" src={logo} alt="Logo" />
        </Link>
        <nav
          className={`bg-secondary sm:bg-white sm:bg-transparent flex sm:flex flex-col sm:flex-row flex-wrap items-center justify-center sm:justify-start
            fixed top-0 left-0 sm:static w-full sm:w-auto h-screen sm:h-full text-2xl sm:text-sm lg:text-base z-50 ${
              menuOpened ? "" : "hidden"
            }`}
        >
          <Link
            to="/"
            className="p-6 sm:p-2 md:p-3 md:mr-3 lg:mr-10 text-white sm:text-black hover:text-gray-700"
            activeClassName="active"
            onClick={(e) => handleNavClick(e)}
          >
            Strona główna
          </Link>
          <div className="dropdown relative p-6 sm:p-2 md:p-3 sm:mr-3 lg:mr-10">
            <button
              className="text-white sm:text-black hover:text-gray-700"
              data-toggle="dropdown"
              aria-haspopup="true"
              aria-expanded="false"
            >
              Oferta
            </button>
            <div className="dropdown-menu">
              <Link
                onClick={(e) => handleNavClick(e)}
                to="/oferta/zlom-elektroniki"
                className="dropdown-item"
              >
                Złom elektroniki
              </Link>
              <Link
                onClick={(e) => handleNavClick(e)}
                to="/oferta/odpady-metali-kolorowych"
                className="dropdown-item"
              >
                Odpady metali kolorowych
              </Link>
              <Link
                onClick={(e) => handleNavClick(e)}
                to="/oferta/zlom-stalowy"
                className="dropdown-item"
              >
                Złom stalowy
              </Link>
              <Link
                onClick={(e) => handleNavClick(e)}
                to="/oferta/odpady-katalizatorow"
                className="dropdown-item"
              >
                Odpady katalizatorów
              </Link>
              <Link
                onClick={(e) => handleNavClick(e)}
                to="/oferta/odpady-kabli"
                className="dropdown-item"
              >
                Odpady kabli
              </Link>
              <Link
                onClick={(e) => handleNavClick(e)}
                to="/oferta/odpady-weglika-spiekanego"
                className="dropdown-item"
              >
                Odpady węglika spiekanego
              </Link>
            </div>
          </div>
          <Link
            to="/o-nas"
            className="p-6 sm:p-2 md:p-3 md:mr-3 lg:mr-10 text-white sm:text-black hover:text-gray-700"
            activeClassName="active"
            onClick={(e) => handleNavClick(e)}
          >
            O nas
          </Link>
          <Link
            to="/kontakt"
            className="p-6 sm:p-2 md:p-3 text-white sm:text-black hover:text-gray-700"
            activeClassName="active"
            onClick={(e) => handleNavClick(e)}
          >
            Kontakt
          </Link>
        </nav>
        <a
          href="tel:606662650"
          className="bg-secondary border-secondary hover:border-opacity-70 text-white border-2 transition duration-500 text-base py-2 px-6 hidden sm:inline-block"
        >
          Zadzwoń do nas
        </a>
        <button
          id="menuBtn"
          className={`hamburger absolute right-3 sm:hidden focus:outline-none ${
            menuOpened ? "opened" : ""
          }`}
          type="button"
          onClick={() => toggleMenu()}
        >
          <span className="hamburger__top-bun"></span>
          <span className="hamburger__bottom-bun"></span>
        </button>
      </div>
    </header>
  );
};

export default Header;

import React from "react";
import { Link } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import config from "../../../config.json";

const Footer = () => {
  return (
    <footer id="contact" className="text-gray-100 body-font bg-section">
      <div className="container mx-auto px-5 py-12 md:py-20 flex flex-row flex-wrap md:flex-nowrap justify-between">
        <div>
          <StaticImage
            src="../../images/logo.png"
            alt="Footer logo"
            layout="fixed"
            width={120}
            className="mb-3"
          />
          <p className="w-80 mb-6">
            Świadczymy kompleksowe usługi w zakresie skupu i przetwarzania
            różnego rodzaju złomu i odpadów.
          </p>
        </div>
        <div className="flex flex-wrap flex-col sm:flex-row">
          <div className="sm:px-10 mb-5 lg:mb-0">
            <h2 className="title-font font-semibold tracking-widest text-md mb-4">
              Usługi
            </h2>
            <div className="flex gap-6">
              <div>
                <Link
                  to="/oferta/zlom-elektroniki"
                  className="text-gray-300 block mb-2"
                >
                  Złom elektroniki
                </Link>
                <Link
                  to="/oferta/odpady-metali-kolorowych"
                  className="text-gray-300 block mb-2"
                >
                  Odpady metali kolorowych
                </Link>
                <Link
                  to="/oferta/zlom-stalowy"
                  className="text-gray-300 block mb-2"
                >
                  Złom stalowy
                </Link>
              </div>
              <div>
                <Link
                  to="/oferta/odpady-katalizatorow"
                  className="text-gray-300 block mb-2"
                >
                  Odpady katalizatorów
                </Link>
                <Link
                  to="/oferta/odpady-kabli"
                  className="text-gray-300 block mb-2"
                >
                  Odpady kabli
                </Link>
                <Link
                  to="/oferta/odpady-weglika-spiekanego"
                  className="text-gray-300 block mb-2"
                >
                  Odpady węglika spiekanego
                </Link>
              </div>
            </div>
          </div>
          <div className="sm:px-10 mb-5 lg:mb-0">
            <h2 className="title-font font-semibold tracking-widest text-md mb-4">
              Firma
            </h2>
            <Link to="/o-nas" className="text-gray-300 block mb-2">
              O nas
            </Link>
            <Link
              to="/polityka-prywatnosci"
              className="text-gray-300 block mb-2"
            >
              Polityka prywatności
            </Link>
          </div>
          <div className="sm:px-10 mb-5 lg:mb-0">
            <h2 className="title-font font-semibold tracking-widest text-sm mb-3">
              Kontakt
            </h2>
            <div className="flex items-center my-2">
              <span className="text-gray-100">E.</span>
              <a href={`mailto:${config.email}`} className="text-gray-300 ml-2">
                {config.email}
              </a>
            </div>
            <div className="flex items-start my-2">
              <span className="text-gray-100">T.</span>
              <a
                href={`tel:${config.phone}`}
                className="text-gray-300 ml-2 block"
              >
                {config.phone}
              </a>
            </div>
            <div className="flex items-start my-2">
              <span className="text-gray-100">A.</span>
              <span className="text-gray-300 ml-2">{config.address}</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

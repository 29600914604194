import React from "react";
import { Helmet } from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import config from "../../../config.json";

const Seo = ({ description, lang, title, titleTemplate }) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            siteUrl
            googleSiteVerification
          }
        }
      }
    `
  );

  const pageTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;
  const metaTitleTemplate = titleTemplate || `%s - ${site.siteMetadata.title}`;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={pageTitle}
      titleTemplate={metaTitleTemplate}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          name: `google-site-verification`,
          content: site.siteMetadata.googleSiteVerification,
        },
      ]}
    >
      <script type="application/ld+json">
        {`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "url": ${site.siteMetadata.siteUrl},
          "name": ${site.siteMetadata.title},
          "contactPoint": {
            "@type": "ContactPoint",
            "telephone": ${config.phone},
            "contactType": "Agent"
          }
        }
      `}
      </script>
    </Helmet>
  );
};

export default Seo;
